import React from "react";
import { Card, Typography } from "antd";

// const { Paragraph, Text } = Typography;
export default function PointEarn() {
  return (
    <Card
      bordered={true}
      className="header-solid mb-24 mt-0"
      title={
        <>
          <h6 className="font-semibold">เกี่ยวกับคะแนน</h6>
          <p>
            สะสมคะแนนเพื่อแลกของรางวัล
            <br />
            100 บาท = 10 คะแนน
            <br />
            <br />
            โดยคะแนนจะได้จากยอดซื้อสินค้าทางไอจีไลฟ์ (ไม่รวมค่าส่ง)
            <br />
            <br />
            สามารถเก็บสะสมได้นานไปเรื่อยๆ ไม่มีกำหนดหมดอายุการใช้งาน
            (หากมีการเปลี่ยนแปลงจะแจ้งให้ทราบ)
            <br />
            <br />
            วิธีแลกของรางวัล
            <br />
            - ลูกค้าต้องมียอดซื้อในรอบไลฟ์นั้น
            เท่านั้น(ทางร้านจะจัดส่งของรางวัลไปพร้อมสินค้า)
            <br />- แจ้งแอดมินทางไลน์ ID : @086mgqnm ก่อนทำการกดแลก
          </p>
          <br />
          <Typography.Link
            style={{ textAlign: "center" }}
            href="https://lin.ee/n4fgOYI"
            target="_blank"
          >
            Line official
          </Typography.Link>
        </>
      }
    ></Card>
  );
}
