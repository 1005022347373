import axios from "axios";
import jwtDecode from "jwt-decode";
import { APPCONFIG } from "../Config";
import { RunAPI } from "./RunApi";
import { message } from "antd";

const fileDownload = require("js-file-download");

// Create axios custom
const axiosApiInstance = axios.create();

const SUCCESS_MESSAGE = `ดำเนินการเรียบร้อย!!`;
const TEXT_ERROR_MESSAGE = `เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง!!`;

const refreshing = async () => {
  const jwt = localStorage.getItem("@aid");
  const refreshToken = localStorage.getItem("@rid");
  if (!jwt || jwt === "" || !refreshToken || refreshToken === "") {
    localStorage.removeItem("@aid");
    localStorage.removeItem("@rid");
    localStorage.removeItem("@uid");
    localStorage.removeItem("@un");

    window.location = "/point";

    throw new Error("not found token!");
  }

  //check time access token expire
  const decoded = jwtDecode(jwt);
  const jwtExp = decoded.exp;
  const nowTime = (Date.now() / 1000).toFixed();
  console.log(jwtExp, nowTime);

  if (jwtExp <= nowTime) {
    const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/user/refresh`;
    const apikey = `${APPCONFIG.APIKEY}`;
    const data = { refreshToken: refreshToken };
    const res = await RunAPI("POST", url, data, apikey);
    if (res.code !== "00000") {
      localStorage.removeItem("@aid");
      localStorage.removeItem("@rid");
      localStorage.removeItem("@uid");
      localStorage.removeItem("@un");

      window.location = "/point";

      throw new Error("token expired!");
    }
    const newJwt = res.data["access-token"];
    const newRefreshToken = res.data["refresh-token"];
    localStorage.setItem("@aid", newJwt);
    localStorage.setItem("@rid", newRefreshToken);
    return newJwt;
  }
  return jwt;
};

// Add a request interceptor
axiosApiInstance.interceptors.request.use(
  async (config) => {
    const accessToken = await refreshing();
    config.headers = {
      "x-api-key": APPCONFIG.APIKEY,
      Authorization: accessToken,
    };
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

export const RunApiRefreshToken = async (
  Method,
  Url,
  Data = {},
  fieldName = "download.pdf"
) => {
  console.log(Method, Url);

  if (Method === "GET") {
    try {
      const response = await axiosApiInstance.get(Url);
      //console.log(JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      return toErrorHandleLog(error);
    }
  }
  if (Method === "POST") {
    try {
      const response = await axiosApiInstance.post(Url, Data);
      //console.log(JSON.stringify(response.data));
      if (response.code === "00000") {
        message.success(SUCCESS_MESSAGE);
      }

      return response.data;
    } catch (error) {
      return toErrorHandleLog(error);
    }
  }
  if (Method === "PUT") {
    try {
      const response = await axiosApiInstance.put(Url, Data);
      //console.log(JSON.stringify(response.data));
      message.success(SUCCESS_MESSAGE);
      return response.data;
    } catch (error) {
      return toErrorHandleLog(error);
    }
  }
  if (Method === "DELETE") {
    try {
      const response = await axiosApiInstance.delete(Url);
      //console.log(JSON.stringify(response.data));
      message.success(SUCCESS_MESSAGE);
      return response.data;
    } catch (error) {
      return toErrorHandleLog(error);
    }
  }
  if (Method === "DOWNLOAD") {
    try {
      const response = await axiosApiInstance.get(Url, {
        responseType: "arraybuffer",
      });
      console.log(response.data);
      fileDownload(response.data, fieldName);
    } catch (error) {
      return toErrorHandleLog(error);
    }
  }
};

const showErrorPopup = () => {
  message.error(TEXT_ERROR_MESSAGE);
};

const toErrorHandleLog = (error) => {
  showErrorPopup();
  if (error.response) {
    // Request made and server responded
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error.message);
  }
  const errorData = {
    _error: true,
    _error_data: error,
  };

  if (
    error.message !== "token expired!" &&
    error.message !== "not found token!"
  ) {
    //store.dispatch(updateSuperServerError(true));
    // todo
  }
  return errorData;
};
