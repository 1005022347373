import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Typography, Image } from "antd";
import { useHistory } from "react-router-dom";
import moment from "moment";

import { getAllRewards } from "../../services/rewards";

const { Paragraph, Text } = Typography;

export default function PointRedeem({ userPoint = 0 }) {
  const history = useHistory();

  const [redeemList, setRedeemList] = useState([]);

  useEffect(() => {
    getRewards();
  }, []);

  const getRewards = async () => {
    const resp = await getAllRewards();
    if (!resp._error) {
      setRedeemList(resp.data);
    }
  };

  const renderSubmitButton = (item) => {
    const usePoint = item.use_point;
    const amount = item.amount;
    const currentDateTime = new Date();
    const endDateTime = new Date(item.end_datetime);

    if (amount <= 0) {
      return (
        <Button type="button" disabled style={{ width: "90%" }}>
          แลกหมดแล้ว
        </Button>
      );
    } else if (currentDateTime > endDateTime) {
      return (
        <Button type="button" disabled style={{ width: "90%" }}>
          หมดเขตการแลก
        </Button>
      );
    } else if (userPoint < usePoint) {
      return (
        <Button type="button" disabled style={{ width: "90%" }}>
          คะแนนไม่พอ
        </Button>
      );
    } else
      return (
        <Button
          type="button"
          style={{ width: "90%" }}
          onClick={() =>
            history.push({
              pathname: "/point-detail",
              state: { item: item },
            })
          }
        >
          แลกคะแนน
        </Button>
      );
  };

  return (
    <Card
      bordered={true}
      className="header-solid mb-24 mt-0"
      title={
        <>
          <h6 className="font-semibold">แลกคะแนน</h6>

          <p>นำคะแนนสะสมแลกของรางวัล</p>
        </>
      }
    >
      <Row gutter={[24, 24]}>
        {redeemList.length > 0 &&
          redeemList.map((p, index) => (
            <Col span={24} xs={12} md={12} xl={6} key={index}>
              <Card
                bordered={true}
                hoverable
                cover={
                  <Image
                    style={{ aspectRatio: "16/10", objectFit: "cover" }}
                    src={p.image_cover_url}
                  />
                }
                actions={[renderSubmitButton(p)]}
              >
                <div style={{ height: 60, overflow: "hidden" }}>
                  <Paragraph ellipsis={{ rows: 2, expandable: false }}>
                    <h6>{p.name}</h6>
                  </Paragraph>
                </div>
                <div>
                  <div>
                    <Text>ใช้คะแนน </Text>
                    <Text strong>{p.use_point} คะแนน</Text>
                  </div>
                  <div>
                    <Text>เหลือสิทธิ์ </Text>
                    <Text>{p.amount}</Text>
                  </div>
                  <div>
                    <Text type="secondary">หมดเขต </Text>
                    <Text type="secondary">
                      {moment(p.end_datetime).format("DD-MM-YYYY")}
                    </Text>
                  </div>
                </div>
                {(p.amount <= 0 || new Date() > new Date(p.end_datetime)) && (
                  <div
                    style={{
                      width: 120,
                      height: 120,
                      background: "rgba(0, 0, 0, 0.5)",
                      borderRadius: 60,
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    {p.amount <= 0 ? "หมดแล้ว" : "หมดเขต"}
                  </div>
                )}
              </Card>
            </Col>
          ))}

        {/* <Col span={24} xs={12} md={12} xl={6} key={"index"}>
          <Card
            bordered={true}
            hoverable
            cover={<img alt="example" src={redeemList[0].img} />}
            actions={[
              <Button type="button" disabled style={{ width: "90%" }}>
                แลกหมดแล้ว
              </Button>,
            ]}
          >
            <Paragraph ellipsis={{ rows: 2, expandable: false }}>
              <h6>
                dasdahskjd asjdha skdjha skjdh asjkdh kajsaadsasjd asjkd
                asdjaskdhas dashj
              </h6>
            </Paragraph>
            <div>
              <div>
                <Text>คะแนนที่ใช้แลก </Text>
                <Text strong>111</Text>
              </div>
              <div>
                <Text>เหลือสิทธิ์ </Text>
                <Text>0</Text>
              </div>
              <div>
                <Text type="secondary">หมดเขต </Text>
                <Text type="secondary">12/12/2566</Text>
              </div>
            </div>
            <div
              style={{
                width: 120,
                height: 120,
                background: "rgba(0, 0, 0, 0.5)",
                borderRadius: 60,
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              หมดแล้ว
            </div>
          </Card>
        </Col> */}
      </Row>
    </Card>
  );
}
