import React, { useState, useEffect } from "react";
import { Card, List, Avatar, Divider } from "antd";
import moment from "moment";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { getMePointTransection } from "../../services/user";

export default function PointTransection() {
  const [transectionList, setTransectionList] = useState([]);

  useEffect(() => {
    getRewards();
  }, []);

  const getRewards = async () => {
    const resp = await getMePointTransection();
    if (!resp._error) {
      if (resp.data.length > 0) {
        const mapData = resp.data.map((v) => {
          return {
            avatar:
              v.is_earn === 1 ? (
                <PlusOutlined style={{ fontSize: 10 }} />
              ) : (
                <MinusCircleOutlined style={{ fontSize: 10 }} />
              ),
            title: v.is_earn === 1 ? "เพิ่มคะแนนสะสม" : "หักคะแนนสะสม",
            description:
              v.is_earn === 1
                ? v.note
                : v.reward_name_snap.length > 0
                ? v.reward_name_snap
                : "" + v.note.length > 0
                ? " *" + v.note
                : "",
            created_at: v.created_at,
            amount: v.point >= 0 ? `+ ${v.point}` : `- ${v.point}`,
            textclass: v.is_earn === 1 ? "text-fill" : "text-light-danger",
            amountcolor: v.is_earn === 1 ? "text-success" : "text-danger",
          };
        });
        setTransectionList(mapData);
      }
    }
  };

  return (
    <Card
      bordered={true}
      className="header-solid mb-24 mt-0"
      title={
        <>
          <h6 className="font-semibold">ประวัติคะแนน</h6>
          <p>รายการสะสมคะแนน แลกคะแนน</p>
        </>
      }
    >
      <List
        className="transactions-list ant-newest"
        itemLayout="horizontal"
        dataSource={transectionList}
        pagination
        renderItem={(item) => (
          <>
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar size="small" className={item.textclass}>
                    {item.avatar}
                  </Avatar>
                }
                title={item.title}
                description={
                  <>
                    <div style={{ height: 20 }}>{item.description}</div>
                    <div style={{ height: 20, fontSize:10 }}>{moment(item.created_at).format("DD-MM-YYYY HH:mm:ss")}</div>
                  </>
                }
              />
              <div className="amount">
                <span className={item.amountcolor}>{item.amount}</span>
              </div>
            </List.Item>
            <Divider style={{ margin: 0 }} />
          </>
        )}
      />
    </Card>
  );
}
