import React, { useState } from "react";

import { Row, Col, Card, Button, Form, Typography, Input, Modal } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import UserInfo from "../components/users/UserInfo";

// import OtpInput from "react-otp-input";

import { requestOtp, verifiedOtp } from "../services/otp";

const { Text, Title } = Typography;
export default function VerifyMobile() {
  const history = useHistory();

  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [alertReqOtp, setAlertReqOtp] = useState("");
  const [alertErrOtp, setAlertErrOtp] = useState("");

  const [form] = Form.useForm();
  const formItemLayout = {};

  const [otp, setOtp] = useState("");
  const [token, setToken] = useState("");

  const onSubmitMobile = async () => {
    setLoading(true);
    setAlertReqOtp("");
    form
      .validateFields()
      .then(async (values) => {
        const data = {
          mobile: values.mobile,
        };
        const resp = await requestOtp(data);
        setLoading(false);
        if (!resp._error) {
          if (resp.code === "00000") {
            setVisible(true);
            setToken(resp.data.token);
          } else if (resp.code === "00500") {
            setAlertReqOtp("คุณขอรหัสเกินจำนวนที่กำหนด ลองใหม่อีกครั้งภายหลัง");
          } else if (resp.code === "00100") {
            setAlertReqOtp("เบอร์นี้มีการใช้งานเรียบร้อยแล้ว");
          }
        }
      })
      .catch((info) => {
        setLoading(false);
      });
  };

  const onVerifyOtp = async () => {
    setAlertErrOtp("");
    setLoading(true);
    const data = {
      mobile: mobile,
      token: token,
      pin: otp,
    };
    const resp = await verifiedOtp(data);
    setLoading(false);
    if (!resp._error) {
      if (resp.code === "00100") {
        setAlertErrOtp("รหัส OTP ไม่ถูกต้อง!");
      } else if (resp.code === "00000") {
        const JWT = resp.data["access-token"];
        const RefreshToken = resp.data["refresh-token"];
        localStorage.setItem("@aid", JWT);
        localStorage.setItem("@rid", RefreshToken);
        setVisible(false);
        history.push("/point");
      }
    }
  };

  return (
    <>
      <UserInfo />
      <Card
        bordered
        className="card-profile-head"
        style={{ marginTop: 20 }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 12, offset: 6 }}
              md={{ span: 12, offset: 6 }}
            >
              <Title style={{ textAlign: "center" }} level={4}>
                ยืนยันเบอร์มือถือเพื่อดำเนินการต่อ
              </Title>

              <Form
                {...formItemLayout}
                layout={"horizontal"}
                form={form}
                initialValues={{
                  is_earn: 1,
                }}
              >
                <Form.Item
                  //   label="เบอร์มือถือ"
                  name="mobile"
                  required
                  rules={[
                    {
                      required: true,
                      message: "กรุณาระบุเบอร์มือถือ!",
                    },
                  ]}
                >
                  <Input
                    placeholder="ระบุเบอร์มือถือ"
                    type="number"
                    value={mobile}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => {
                      e.target.value.length <= 10 && setMobile(e.target.value);
                    }}
                    style={{ width: "100%" }}
                  />
                </Form.Item>

                {alertReqOtp.length > 0 && (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: 10,
                    }}
                  >
                    <Text style={{ textAlign: "center", color: "red" }}>
                      {alertReqOtp}
                    </Text>
                  </div>
                )}

                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => onSubmitMobile()}
                  style={{
                    width: "100%",
                  }}
                >
                  <CheckCircleOutlined /> ยืนยันขอรหัส OTP
                </Button>
              </Form>
            </Col>
          </Row>
        }
      ></Card>

      <Modal
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 220px)" }}
        centered
        open={visible}
        title={"ยืนยันรหัส OTP"}
        // onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        footer={[
          <Button
            key="submit"
            loading={loading}
            onClick={() => setVisible(false)}
          >
            ยกเลิก
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => onVerifyOtp()}
            disabled={otp.length < 6}
          >
            ยืนยันรหัส OTP
          </Button>,
        ]}
      >
        <div style={{ width: "100%", textAlign: "center", marginBottom: 10 }}>
          <Text style={{ textAlign: "center" }}>
            กรอกรหัส OTP ที่ได้รับจากหมายเลข
          </Text>
          <br />
          <Text style={{ textAlign: "center" }}>{mobile}</Text>
        </div>

        {/* <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span> - </span>}
          renderInput={(props) => <input {...props} />}
          inputStyle={{ width: 34, height: 40, fontSize: 18 }}
          containerStyle={{ justifyContent: "center" }}
          inputType="tel"
        /> */}

        <Input
          placeholder="กรอกรหัส OTP"
          type="number"
          onWheel={(e) => e.target.blur()}
          value={otp}
          onChange={(e) => {
            e.target.value.length <= 6 && setOtp(e.target.value);
          }}
          style={{ width: "100%", textAlign: "center" }}
        />

        {alertErrOtp.length > 0 && (
          <div style={{ width: "100%", textAlign: "center", marginTop: 10 }}>
            <Text style={{ textAlign: "center", color: "red" }}>
              {alertErrOtp}
            </Text>
          </div>
        )}
      </Modal>
    </>
  );
}
