import { Layout } from "antd";
import Footer from "./Footer";

const { Content } = Layout;

function MainWithOutSide({ children }) {
  return (
    <div
      style={{
        display: "block",
        maxWidth: 1200,
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Layout style={{ minHeight: "100vh" }}>
        <Layout>
          <Content className="content-ant">{children}</Content>
          <Footer />
        </Layout>
      </Layout>
    </div>
  );
}

export default MainWithOutSide;
