import { RunAPI } from "../../utils/RunApi";
import { APPCONFIG } from "../../Config";
import jwtDecode from "jwt-decode";

export const authentication = async () => {
  try {
    const liff = window.liff;
    await liff.init({
      liffId: APPCONFIG.LINE_LIEF_ID,
      withLoginOnExternalBrowser: true,
    });

    if (liff.isLoggedIn()) {
      const profile = await liff.getProfile();
      const data = {
        email: liff.getDecodedIDToken().email,
        line_user_id: profile.userId,
        line_user_name: profile.displayName,
        line_user_profile: profile.pictureUrl,
      };
      console.log(data);

      const isJwt = localStorage.getItem("@aid");
      if (!isJwt) {
        const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/user/authentication`;
        const apikey = `${APPCONFIG.APIKEY}`;
        const res = await RunAPI("POST", url, data, apikey);
        if (!res._error && res.data) {
          if (res.code === "00000") {
            const JWT = res.data["access-token"];
            const RefreshToken = res.data["refresh-token"];
            const decoded = jwtDecode(JWT);
            localStorage.setItem("@aid", JWT);
            localStorage.setItem("@rid", RefreshToken);
            localStorage.setItem("@uid", JSON.stringify(decoded["user_id"]));
            localStorage.setItem(
              "@un",
              JSON.stringify(decoded["line_user_name"])
            );

            if (decoded["is_verified"] !== 1) {
              window.location = "/verify-mobile";
            }
          }
        }
      } else {
        const decoded = jwtDecode(isJwt);
        if (decoded["is_verified"] !== 1) {
          window.location = "/verify-mobile";
        }
      }
    } else {
      liff.login();
      console.log("islogin", liff.isLoggedIn());
    }
  } catch (error) {
    console.log(error);
  }
};
