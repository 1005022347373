import { Layout, Row, Col, Typography } from "antd";

const { Link } = Typography;

function Footer() {
  const { Footer: AntFooter } = Layout;

  return (
    <AntFooter style={{ background: "#fff" }}>
      <Row className="just">
        <Col xs={24} md={12} lg={12}>
          <div className="copyright">
            <b>OOTD-FITTING</b>
          </div>
        </Col>
        <Col xs={24} md={12} lg={12}>
          <div className="footer-menu">
            <ul>
              <li className="nav-item">
                <Link href="https://instagram.com/ootd.fitting" target="_blank">
                  Instagram
                </Link>
              </li>
              <li className="nav-item">
                <Link href="https://lin.ee/n4fgOYI" target="_blank">
                  Line official
                </Link>
              </li>
              <li className="nav-item">
                <Link href="https://www.tiktok.com/@pimyo.o" target="_blank">
                  TikTok
                </Link>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </AntFooter>
  );
}

export default Footer;
