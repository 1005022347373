import { APPCONFIG } from "../Config";
import { RunApiRefreshToken } from "../utils/RunApiRefreshToken";

export const getMe = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/user/me`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};

export const getMePointTransection = async () => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/point/user/transection`;
  const res = await RunApiRefreshToken("GET", url, {});
  return res;
};
