import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Carousel,
  Image,
  Button,
  Breadcrumb,
  Popconfirm,
  Modal,
  Typography,
} from "antd";
import moment from "moment";

import {
  HomeOutlined,
  GiftOutlined,
  SwapOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import UserPoint from "../components/users/UserPoint";
import UserInfoSmall from "../components/users/UserInfoSmall";
import { userBurnPoint } from "../services/point";

function PointDetail({ location }) {
  const history = useHistory();
  const item = location.state.item;
  const [modal, contextHolder] = Modal.useModal();
  const [userInfo, setUserInfo] = useState(null);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const confirm = async () => {
    setLoading(true);
    const data = {
      reward_id: item.ID,
    };
    const resp = await userBurnPoint(data);
    setLoading(false);
    if (!resp._error) {
      if (resp.code === "00000") {
        setSuccessModalOpen(true);
        return;
      }
      if (resp.code === "00201") {
        modal.warning(config201);
        return;
      }
      if (resp.code === "00202") {
        modal.warning(config202);
        return;
      }
      if (resp.code === "00203") {
        modal.warning(config203);
        return;
      }
    } else {
      modal.warning(config500);
      return;
    }
  };
  const cancel = () => {};

  const config201 = {
    title: "เกิดข้อผิดพลาด!",
    content: <>ไม่สามารถแลกได้ในขณะนี้ เนื่องจากของรางวัลได้หมดลงแล้ว</>,
    okText: "ตกลง",
  };

  const config202 = {
    title: "เกิดข้อผิดพลาด!",
    content: <>ไม่สามารถแลกได้ในขณะนี้ เนื่องจากหมดเขตแลกแล้ว</>,
    okText: "ตกลง",
  };

  const config203 = {
    title: "เกิดข้อผิดพลาด!",
    content: <>ไม่สามารถแลกได้ เนื่องจากคะแนนสะสมยังไม่เพียงพอ</>,
    okText: "ตกลง",
  };

  const config500 = {
    title: "เกิดข้อผิดพลาด!",
    content: <>เกิดข้อผิดพลาด! กรุณาลองใหม่อีกครั้ง!</>,
    okText: "ตกลง",
  };

  const renderSubmitButton = (item) => {
    const userPoint = userInfo?.point || 0;
    const usePoint = item.use_point;
    const amount = item.amount;
    const currentDateTime = new Date();
    const endDateTime = new Date(item.end_datetime);

    if (amount <= 0) {
      return (
        <Button
          icon={<GiftOutlined />}
          shape="round"
          disabled
          style={{ width: "100%" }}
        >
          รางวัลหมดแล้ว
        </Button>
      );
    } else if (currentDateTime > endDateTime) {
      return (
        <Button
          icon={<GiftOutlined />}
          shape="round"
          disabled
          style={{ width: "100%" }}
        >
          หมดเขตการแลก
        </Button>
      );
    } else if (userPoint < usePoint) {
      return (
        <Button
          icon={<GiftOutlined />}
          shape="round"
          disabled
          style={{ width: "100%" }}
        >
          คะแนนไม่เพียงพอ
        </Button>
      );
    } else
      return (
        <Popconfirm
          title="คุณต้องการยืนยันแลกคะแนน?"
          onConfirm={confirm}
          onCancel={cancel}
          okText="ยืนยัน"
          cancelText="ยกเลิก"
        >
          <Button
            icon={<GiftOutlined />}
            shape="round"
            type="primary"
            style={{ width: "100%" }}
            loading={loading}
            disabled={loading}
          >
            แลกคะแนน
          </Button>
        </Popconfirm>
      );
  };

  return (
    <>
      <UserPoint callbackSetUserInfo={setUserInfo} />
      <Card bordered={true} className="header-solid">
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/point">
            <GiftOutlined />
            <span>คะแนน</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>แลกคะแนน</Breadcrumb.Item>
        </Breadcrumb>
      </Card>
      <Card bordered={true} className="header-solid mb-24">
        <Row gutter={[24, 0]}>
          <Col span={24} md={14} xs={24}>
            <Carousel autoplay>
              {item.image_cover_url && (
                <div>
                  <Image
                    style={{ aspectRatio: "16/10", objectFit: "cover" }}
                    src={item.image_cover_url}
                  />
                </div>
              )}
              {item.image_slide1_url && (
                <div>
                  <Image
                    style={{ aspectRatio: "16/10", objectFit: "cover" }}
                    src={item.image_slide1_url}
                  />
                </div>
              )}
              {item.image_slide2_url && (
                <div>
                  <Image
                    style={{ aspectRatio: "16/10", objectFit: "cover" }}
                    src={item.image_slide2_url}
                  />
                </div>
              )}
              {item.image_slide3_url && (
                <div>
                  <Image
                    style={{ aspectRatio: "16/10", objectFit: "cover" }}
                    src={item.image_slide3_url}
                  />
                </div>
              )}
              {item.image_slide4_url && (
                <div>
                  <Image
                    style={{ aspectRatio: "16/10", objectFit: "cover" }}
                    src={item.image_slide4_url}
                  />
                </div>
              )}
              {item.image_slide5_url && (
                <div>
                  <Image
                    style={{ aspectRatio: "16/10", objectFit: "cover" }}
                    src={item.image_slide5_url}
                  />
                </div>
              )}
            </Carousel>
          </Col>
          <Col span={24} md={10} xs={24}>
            <Card bordered={true} className="header-solid mb-24">
              <>
                <h6 className="font-semibold">{item.name}</h6>
                <p>
                  คะแนนที่ใช้แลก : <b>{item.use_point} คะแนน</b>
                  <br />
                  เหลือสิทธิ์: {item.amount}
                  <br />
                  การแลก:{" "}
                  {item.is_per_user === 1 ? `1 ครั้งต่อคน` : `แลกได้ตลอด`}
                  <br />
                  หมดเขต: {moment(item.end_datetime).format("DD-MM-YYYY")}
                </p>
                <p>
                  {item.condition}
                  <br />
                </p>
              </>
            </Card>
            <Card bordered={true} className="header-solid mb-24">
              {renderSubmitButton(item)}
            </Card>
          </Col>
        </Row>
        {contextHolder}
        <Modal
          title={null}
          open={successModalOpen}
          onOk={() => history.push("/point")}
          onCancel={() => history.push("/point")}
          maskClosable={false}
          footer={null}
          bodyStyle={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ color: "green", fontSize: 18 }}>
            <CheckCircleFilled />
            <Typography.Text
              strong
              style={{ color: "green", fontSize: 18, marginLeft: 4 }}
            >
              แลกคะแนนสำเร็จ
            </Typography.Text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <div
              style={{
                width: 160,
                height: 100,
                borderRadius: 8,
                backgroundColor: "#f5f5f5",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              {item.use_point} <br />
              คะแนน
            </div>
            <div
              style={{
                width: 30,
                height: 30,
                borderRadius: 15,
                backgroundColor: "#7cb305",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: 4,
              }}
            >
              <SwapOutlined style={{ fontSize: 20, color: "white" }} />
            </div>
            <div
              style={{
                width: 160,
                height: 100,
                borderRadius: 8,
                backgroundColor: "red",
              }}
            >
              <Image
                style={{ aspectRatio: "16/10", objectFit: "cover" }}
                src={item.image_cover_url}
              />
            </div>
          </div>

          <h6 className="font-semibold">{item.name}</h6>
          <UserInfoSmall />
          <p style={{ marginTop: 10 }}>
            *สามารถแคปหน้าจอและแจ้งแอดมินทางไลน์ ID : @086mgqnm
          </p>
          <Typography.Link
            style={{ textAlign: "center" }}
            href="https://lin.ee/n4fgOYI"
            target="_blank"
          >
            Line official
          </Typography.Link>
        </Modal>
      </Card>
    </>
  );
}

export default PointDetail;
