import React, { useState, useEffect } from "react";
import { Row, Col, Card, Breadcrumb, Tabs, Space } from "antd";
import {
  GiftOutlined,
  FieldTimeOutlined,
  InfoCircleOutlined,
  HomeOutlined,
} from "@ant-design/icons";

import PointRedeem from "../components/point/PointRedeem";
import PointTransection from "../components/point/PointTransection";
import PointEarn from "../components/point/PointEarn";
import UserPoint from "../components/users/UserPoint";
import PageLoading from "../components/page-loading/PageLoading";

import { authentication } from "../services/auth/auth";

function Point() {
  const [pageLoading, setPageLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    lineLogin();
  }, []);

  const lineLogin = async () => {
    await authentication();
    setPageLoading(false);
  };

  const items = [
    {
      label: (
        <Space>
          <GiftOutlined />
          แลกคะแนน
        </Space>
      ),
      key: `tab1`,
      children: <PointRedeem userPoint={userInfo?.point} />,
    },
    {
      label: (
        <Space>
          <FieldTimeOutlined />
          ประวัติคะแนน
        </Space>
      ),
      key: `tab2`,
      children: <PointTransection />,
    },
    {
      label: (
        <Space>
          <InfoCircleOutlined />
          เกี่ยวกับคะแนน
        </Space>
      ),
      key: `tab3`,
      children: <PointEarn />,
    },
  ];

  return (
    <>
      {pageLoading && <PageLoading />}
      {!pageLoading && (
        <>
          <UserPoint callbackSetUserInfo={setUserInfo} />
          <Card bordered={true} className="header-solid mb-24">
            <Breadcrumb>
              <Breadcrumb.Item href="/">
                <HomeOutlined />
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/point">
                <GiftOutlined />
                <span>คะแนน</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Card>
          <Row gutter={[24, 0]}>
            <Col span={24} md={24} className="mb-24">
              <Tabs
                defaultActiveKey="1"
                type="card"
                items={items}
                size="large"
                tabBarStyle={{ marginBottom: 0 }}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default Point;
