import { Image } from "antd";

import HomeImage from "../assets/images/homeImage.png";

function Home() {
  return (
    <>
      <div className="layout-content">
        <center>
          <Image src={HomeImage} />
        </center>
      </div>
    </>
  );
}

export default Home;
