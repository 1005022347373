import React, { useEffect, useState } from "react";
import { Card, Row, Col, Avatar } from "antd";

import BgProfile from "../../assets/images/bg-point-header.png";

import { getMe } from "../../services/user";
export default function UserPoint({ callbackSetUserInfo = () => {} }) {
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    InitData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const InitData = async () => {
    const resp = await getMe();
    if (!resp._error) {
      setUserInfo(resp.data);
      callbackSetUserInfo(resp.data);
    }
  };
  return (
    <>
      <div
        className="profile-nav-bg"
        style={{ backgroundImage: "url(" + BgProfile + ")" }}
      />

      <Card
        className="card-profile-head"
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={16} xs={24} className="col-info">
              {userInfo && (
                <Avatar.Group>
                  <Avatar
                    size={74}
                    shape="square"
                    src={userInfo.line_user_profile}
                  />
                  <div className="avatar-info">
                    <h4 className="font-semibold m-0">
                      {userInfo.line_user_name}
                    </h4>
                    <p>{userInfo.email}</p>
                  </div>
                </Avatar.Group>
              )}
            </Col>
            <Col span={8} md={8} xs={24}>
              <Card
                bordered={false}
                className="card-credit"
                style={{ borderRadius: 10 }}
              >
                {userInfo && (
                  <div
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <div>
                      <h3 style={{ marginBottom: 0 }}>
                        คะแนนสะสมของคุณ
                        <br />
                        <b>{userInfo.point} คะแนน</b>
                      </h3>
                    </div>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        }
      ></Card>
    </>
  );
}
