import React, { useEffect, useState } from "react";
import { Card, Row, Col, Avatar } from "antd";

import { getMe } from "../../services/user";
export default function UserInfoSmall() {
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    InitData();
  }, []);

  const InitData = async () => {
    const resp = await getMe();
    if (!resp._error) {
      setUserInfo(resp.data);
    }
  };
  return (
    <>
      <Card
        bodyStyle={{ display: "none" }}
        title={
          <Row justify="space-between" align="middle" gutter={[24, 0]}>
            <Col span={24} md={16} xs={24} className="col-info">
              {userInfo && (
                <Avatar.Group>
                  <Avatar
                    size={74}
                    shape="square"
                    src={userInfo.line_user_profile}
                  />
                  <div className="avatar-info" style={{ marginLeft: 10 }}>
                    <h4 className="font-semibold m-0">
                      {userInfo.line_user_name}
                    </h4>
                    <p>{userInfo.email}</p>
                    <p>{userInfo.mobile}</p>
                  </div>
                </Avatar.Group>
              )}
            </Col>
          </Row>
        }
      ></Card>
    </>
  );
}
