import { APPCONFIG } from "../Config";
import { RunApiRefreshToken } from "../utils/RunApiRefreshToken";

export const requestOtp = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/otp/sms/request`;
  const res = await RunApiRefreshToken("POST", url, data);
  return res;
};

export const verifiedOtp = async (data) => {
  const url = `${APPCONFIG.SERVICE_ENDPOINT}/api/v1/otp/sms/verified`;
  const res = await RunApiRefreshToken("POST", url, data);
  return res;
};
