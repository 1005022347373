import React from "react";
import { Spin } from "antd";

export default function PageLoading() {
  return (
    <div
      style={{
        backgroundColor: "white",
        width: " 100%",
        height: "100%",
        position: "fixed",
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        top: 0,
        left: 0,
        zIndex: 120,
        overflow: "hidden",
      }}
    >
      <div
        style={{
          width: 200,
          height: 200,
          textAlign: "center",
          verticalAlign: "middle",
        }}
      >
        <Spin />
        <br />
        Loading . . .
      </div>
    </div>
  );
}
